import React from "react"
import {useTranslation} from "react-i18next"

import CommunitiesMain from "../../assets/img/communities/communities-main.jpg";
import CommunitiesSmall
    from "../../assets/img/communities/communities-small.jpg";
import Communities1 from "../../assets/img/communities/communities-1.jpg";
import Communities1M from "../../assets/img/communities/communities-1M.jpg";

import './communities.components.less';
import {useI18next} from "gatsby-plugin-react-i18next";
import Seo from "../seo";
import communities2 from "../../assets/img/communities/communities-2.jpg";
import communities2M from "../../assets/img/communities/communities-2M.jpg";

const CommunitiesComponent = () => {
    const {i18n, t} = useTranslation();
    const {originalPath} = useI18next();

    return (
        <div>
            <Seo description={t("metaDescriptionCommunities")}
                 lang={i18n.language} title={t("titleCommunities")}
                 link={"https://airvoice.global/" + i18n.language + originalPath}/>
            <div className="communities">
                <div
                    className="wrapper_block wrapper_block__block main-block wrapper_block__block--no-padding-bottom">
                    <div className="main-block__text">
                        <div className="main-block__title">
                            <span
                                dangerouslySetInnerHTML={{__html: t("hyperlocalAirQuality")}}/>
                            <span className="main-block__title-purple"> <span
                                dangerouslySetInnerHTML={{__html: t("forCommunitiesCondos")}}/></span>
                        </div>
                        <div className="main-block__description">
                            <strong><span
                                dangerouslySetInnerHTML={{__html: t("deployTheAirQualityMonitoring")}}/></strong>
                        </div>
                        <div
                            className="main-block__description main-block__description--others">
                            <span
                                dangerouslySetInnerHTML={{__html: t("cityAirIsANearReference")}}/>
                        </div>
                    </div>
                    <div className="main-block__img">
                        <div
                            className="main-block__main-img-block main-block__main-img-block--mobile">
                            <div className="main-block__main-img-block--1"/>
                            <div className="main-block__main-img-block--2">
                                <img alt="" className="main-block__main-img"
                                     src={CommunitiesMain}/>
                                <div className="main-block__artefact"/>
                            </div>
                        </div>
                        <div
                            className="main-block__small-img-block main-block__small-img-block--mobile">
                            <div className="main-block__small-img-block--1">
                                <img alt=""
                                     className="main-block__small-img"
                                     src={CommunitiesSmall}/>
                            </div>
                            <div className="main-block__small-img-block--2"/>
                        </div>
                    </div>
                </div>
                <div
                    className="wrapper_block wrapper_block__block wrapper_block__block--no-border wrapper_block--yellow big-title">
                    <div className="big-title__width">
                        <div
                            dangerouslySetInnerHTML={{__html: t("theSystemKnows")}}/>
                    </div>
                </div>
                <div
                    className="wrapper_block wrapper_block__block wrapper_block--yellow wrapper_block__block--no-border borders-block">
                    <div className="borders-block__col">
                        <div className="borders-block__col-left">
                            <div className="borders-block__col--block">
                                <div className="borders-block__col--number">1
                                </div>
                                <div
                                    className="borders-block__col--title">
                                    <span
                                        dangerouslySetInnerHTML={{__html: t("averageAQIInYourArea")}}/>
                                </div>
                            </div>
                            <div className="borders-block__hr--horizontal"/>
                            <div className="borders-block__col--block">
                                <div className="borders-block__col--number">2
                                </div>
                                <div
                                    className="borders-block__col--title">
                                    <span
                                        dangerouslySetInnerHTML={{__html: t("daypartsWith")}}/>
                                </div>
                            </div>
                            <div
                                className="borders-block__hr--horizontal borders-block__hr--mobile"/>
                        </div>
                        <div className="borders-block__hr"/>
                        <div className="borders-block__col-right">
                            <div className="borders-block__col--block">
                                <div className="borders-block__col--number">3
                                </div>
                                <div className="borders-block__col--title">
                                    <span
                                        dangerouslySetInnerHTML={{__html: t("currentAndRealTime")}}/>
                                </div>
                            </div>
                            <div
                                className="borders-block__hr--horizontal"/>
                            <div className="borders-block__col--block">
                                <div className="borders-block__col--number">4
                                </div>
                                <div
                                    className="borders-block__col--title">
                                    <span
                                        dangerouslySetInnerHTML={{__html: t("pointsAndLocationsIn")}}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <img alt=""
                     className="communities__img communities__img--desktop display-none-mobile"
                     src={Communities1}/>
                <img alt="" className="communities__img display-block-mobile"
                     src={Communities1M}/>
                <div
                    className="wrapper_block wrapper_block__block wrapper_block__block--no-border big-title big-title--col">
                    <div className="big-title__width big-title__width--max">
                        <div dangerouslySetInnerHTML={{__html: t("theDataIsBeingDisplayed")}}/>
                    </div>
                    <div className="big-title__description">
                        <div dangerouslySetInnerHTML={{__html: t("theDataMayHelpLocal")}}/>
                    </div>
                </div>
                <div
                    className="wrapper_block wrapper_block__block wrapper_block__block--no-border wrapper_block__block--no-padding-bottom">
                    <div className="borders-block__col">
                        <div className="borders-block__col-left">
                            <div className="borders-block__col--block">
                                <div className="borders-block__col--number">1
                                </div>
                                <div
                                    className="borders-block__col--title">
                                    <span
                                        dangerouslySetInnerHTML={{__html: t("pinpointSourcesAnd")}}/>
                                </div>
                                <div
                                    className="borders-block__col--subtitle borders-block__col--point">
                                    <span
                                        dangerouslySetInnerHTML={{__html: t("withTheHelpOfAir")}}/>
                                </div>
                            </div>
                            <div
                                className="borders-block__hr--horizontal"/>
                            <div className="borders-block__col--block">
                                <div className="borders-block__col--number">2
                                </div>
                                <div
                                    className="borders-block__col--title">
                                    <span
                                        dangerouslySetInnerHTML={{__html: t("attractNewCitizens")}}/>
                                </div>
                                <div
                                    className="borders-block__col--subtitle borders-block__col--point">
                                    <span
                                        dangerouslySetInnerHTML={{__html: t("openAirQualityData")}}/>
                                </div>
                            </div>
                            <div
                                className="borders-block__hr--horizontal"/>
                            <div className="borders-block__col--block">
                                <div className="borders-block__col--number">3
                                </div>
                                <div
                                    className="borders-block__col--title">
                                    <span
                                        dangerouslySetInnerHTML={{__html: t("increaseCompliance")}}/>
                                </div>
                                <div
                                    className="borders-block__col--subtitle borders-block__col--point">
                                    <span
                                        dangerouslySetInnerHTML={{__html: t("accurateAndVerifiedAir")}}/>
                                </div>
                            </div>
                            <div
                                className="borders-block__hr--horizontal borders-block__hr--mobile"/>
                        </div>
                        <div className="borders-block__hr"/>
                        <div className="borders-block__col-right">
                            <div className="borders-block__col--block">
                                <div className="borders-block__col--number">4
                                </div>
                                <div className="borders-block__col--title">
                                    <span
                                        dangerouslySetInnerHTML={{__html: t("preventDiseases")}}/>
                                </div>
                                <div
                                    className="borders-block__col--subtitle borders-block__col--point">
                                    <span
                                        dangerouslySetInnerHTML={{__html: t("airQualityIsMeasuredIn")}}/>
                                </div>
                            </div>
                            <div
                                className="borders-block__hr--horizontal"/>
                            <div className="borders-block__col--block">
                                <div className="borders-block__col--number">5
                                </div>
                                <div
                                    className="borders-block__col--title">
                                    <span
                                        dangerouslySetInnerHTML={{__html: t("inspireYourNeighbours")}}/>
                                </div>
                                <div
                                    className="borders-block__col--subtitle borders-block__col--point">
                                    <span
                                        dangerouslySetInnerHTML={{__html: t("everyCitizenMayInfluence")}}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className="communities__end-block wrapper_block--padding-bottom wrapper_block wrapper_block__block wrapper_block__block--no-border">
                    <span className="communities__mail" dangerouslySetInnerHTML={{__html: t("toGetASpecialOfferAndIndividual")}}/>
                </div>
            </div>
        </div>)
}

CommunitiesComponent.propTypes = {}

CommunitiesComponent.defaultProps = {}

export default CommunitiesComponent
